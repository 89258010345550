import { combineReducers } from "@reduxjs/toolkit";

import ToastMessageReducer from "./ToastMessage";
import CommonInterfacesReducer from "./CommonInterfaces";

const uiReducers = combineReducers({
  toastMessage: ToastMessageReducer,
  commonInterfaces: CommonInterfacesReducer,
});

export default uiReducers;
