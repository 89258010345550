import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import toastTypes from "@constants/toast-types";

import { IShowToastMessageModel } from "@helpers/uiInterfaces/ui/ToastMessage";

const TOAST_MESSAGE_AUTOHIDE_DURATION = 5;

const initialState = {
  toastRemainingSeconds: 0,
  message: "",
  type: toastTypes.SUCCESS,
};

const toastMessageSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<IShowToastMessageModel>) => {
      state.toastRemainingSeconds = action.payload.seconds
        ? action.payload.seconds
        : TOAST_MESSAGE_AUTOHIDE_DURATION;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },

    hideToast: (state) => {
      state.toastRemainingSeconds = 0;
      state.message = "";
      state.type = toastTypes.SUCCESS;
    },
  },
});

export const toastMessageActions = toastMessageSlice.actions;
export default toastMessageSlice.reducer;
