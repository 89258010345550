import reqCredTypeOptions from "@constants/json-data/master-data/reqCredTypeOptions";
import rushReqOptions from "@constants/json-data/master-data/rushReqOptions";
import {
  ICredFormDataModel,
  ICredTrackingModel,
} from "@helpers/interfaces/cred-tracking";

export const parseCredTrackingData = (
  credTrackingDataList: Array<ICredTrackingModel>
) => {
  const _credTrackingDataList = [];

  for (let credTrackingData of credTrackingDataList) {
    const _credTrackingData = {
      ...credTrackingData,
      credTypeValue: reqCredTypeOptions.find(
        (data) => data.value === credTrackingData.requestCredType
      )?.label,
      processingTypeValue: rushReqOptions.find(
        (data) => data.value === credTrackingData.processingType
      )?.label,
      organization_name: credTrackingData.organizations?.split(",") || ["--NA--"],
    };

    _credTrackingDataList.push(_credTrackingData);
  }

  return _credTrackingDataList;
};
export interface ICredTrackingFormErrors {
  providerNpi: string;
  providerDob: string;
  providerLastName: string;
}

export const validateCredTrackingForm = (values: ICredFormDataModel) => {
  const errors = {} as ICredTrackingFormErrors;

  if (!values.providerNpi) {
    errors.providerNpi = "Required";
  }

  if (!values.providerDob) {
    errors.providerDob = "Required";
  }

  if (!values.providerLastName) {
    errors.providerLastName = "Required";
  }

  return errors;
};
