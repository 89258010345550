import {
  ICredTrackingPaginationModel,
  ICredWorkflowPaginationModel,
} from "@helpers/interfaces/cred-tracking";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  credTrackingList: {} as ICredTrackingPaginationModel,
  credTrackingJobWorkflow: {} as ICredWorkflowPaginationModel,
  error: null as any,
  loading: false,
};

const credTrackingSlice = createSlice({
  name: "credTracking",
  initialState,
  reducers: {
    updateCredTrackingList: (
      state,
      action: PayloadAction<ICredTrackingPaginationModel>
    ) => {
      state.credTrackingList = action.payload;
    },

    updateCredTrackingJobWorkflow: (
      state,
      action: PayloadAction<ICredWorkflowPaginationModel>
    ) => {
      state.credTrackingJobWorkflow = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.credTrackingList = {} as ICredTrackingPaginationModel;
      state.credTrackingJobWorkflow = {} as ICredWorkflowPaginationModel;
      state.loading = false;
      state.error = null;
    },
  },
});

export const credTrackingActions = credTrackingSlice.actions;
export default credTrackingSlice.reducer;
