import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const privilegeViewerListColumns: Array<IApplicationTableColumn> = [
  {
    id: "payerName",
    label: "Payer Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "enrollmentStatus",
    label: "Privileging Status",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "privileges",
    label: "Privileges",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "category",
    label: "Category",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "enrollmentDate",
    label: "From Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "enrollmentExpiryDate",
    label: "To Date",
    type: applicationTableColumnsTypes.DATE,
  },
];

export default privilegeViewerListColumns;
