import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  displayAuthorizationFailedDialog: false,
};

const commonInterfaces = createSlice({
  name: "commonInterfaces",
  initialState,
  reducers: {
    updateDisplayAuthorizationFailedDialog: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.displayAuthorizationFailedDialog = action.payload;
    },
  },
});

export const commonInterfacesActions = commonInterfaces.actions;
export default commonInterfaces.reducer;
