import { IPrivilegePaginationModel } from "@helpers/interfaces/privilege-viewer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  privilegeList: {} as IPrivilegePaginationModel,
  error: null as any,
  loading: false,
};

const privilegeSlice = createSlice({
  name: "privilege",
  initialState,
  reducers: {
    updatePrivilegeList: (
      state,
      action: PayloadAction<IPrivilegePaginationModel>
    ) => {
      state.privilegeList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.privilegeList = {} as IPrivilegePaginationModel;
      state.loading = false;
      state.error = null;
    },
  },
});

export const privilegeActions = privilegeSlice.actions;
export default privilegeSlice.reducer;
