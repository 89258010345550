import ncqaLogo from "@images/ncqa-logo.jpg";
import styles from "./Footer.module.scss";
import moment from "moment";

const Footer = () => {
  const ncqaLogoAttributes = {
    src: ncqaLogo,
    className: styles.logo,
  };

  const currentYear = moment().year();

  return (
    <footer className={`main-footer ${styles.footerMain}`}>
      <div className={styles.logoContainer}>
        <img {...ncqaLogoAttributes} alt="application-logo" />
      </div>
      <label className={styles.copyrightLabel}>
        © {currentYear} - e2o Health. All rights reserved
      </label>
    </footer>
  );
};

export default Footer;
