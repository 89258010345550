import React, { useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";

import toastTypes from "@constants/toast-types";

import { IComponentChildrenProp } from "helpers/uiInterfaces/common";

import { useAppSelector } from "@hooks/index";

import { toastMessageActions } from "@features/ui/ToastMessage";

import { ReactComponent as SuccessIcon } from "@images/check-icon.svg";
import { ReactComponent as DangerIcon } from "@images/error-icon.svg";
import { ReactComponent as WarningIcon } from "@images/warning-icon.svg";
import { ReactComponent as InfoIcon } from "@images/info-icon.svg";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";

import styles from "./ToastMessage.module.scss";

const ToastMessage: React.FC<IComponentChildrenProp> = (props) => {
  const dispatch = useDispatch<any>();

  const toastMessageState = useAppSelector((state) => state.ui.toastMessage);

  const remainingSeconds = toastMessageState.toastRemainingSeconds;

  const hideToastMessage = useCallback(() => {
    dispatch(toastMessageActions.hideToast());
  }, [dispatch]);

  useEffect(() => {
    if (remainingSeconds === 0) {
      return;
    }

    const remainingMiliseconds = remainingSeconds * 1000;

    // hiding toast message after TOAST_MESSAGE_AUTOHIDE_DURATION
    const timerReference = setTimeout(() => {
      hideToastMessage();
    }, remainingMiliseconds);

    return () => {
      clearTimeout(timerReference);
    };
  }, [remainingSeconds, hideToastMessage]);

  function renderToastMessageIcon() {
    if (toastMessageState.type === toastTypes.SUCCESS) {
      return <SuccessIcon className={styles.successIcon} />;
    }

    if (toastMessageState.type === toastTypes.DANGER) {
      return <DangerIcon className={styles.dangerIcon} />;
    }

    if (toastMessageState.type === toastTypes.WARNING) {
      return <WarningIcon className={styles.warningIcon} />;
    }

    return <InfoIcon className={styles.toastMessageIcon} />;
  }

  function renderToastMessage() {
    if (remainingSeconds === 0) {
      return;
    }

    let toastMessageClassName = styles.toastMessageMain;

    if (toastMessageState.type === toastTypes.DANGER) {
      toastMessageClassName += ` ${styles.danger}`;
    }

    if (toastMessageState.type === toastTypes.SUCCESS) {
      toastMessageClassName += ` ${styles.success}`;
    }

    if (toastMessageState.type === toastTypes.WARNING) {
      toastMessageClassName += ` ${styles.warning}`;
    }

    const closeControlAttributes = {
      className: styles.closeControl,
      onClick: hideToastMessage,
    };

    return (
      <div className={toastMessageClassName}>
        {renderToastMessageIcon()}
        <label className={styles.toastMessage}>
          {toastMessageState.message}
        </label>
        <button {...closeControlAttributes}>
          <CloseIcon className={styles.closeIcon} />
        </button>
      </div>
    );
  }

  return (
    <React.Fragment>
      {renderToastMessage()}
      {props.children}
    </React.Fragment>
  );
};

export default ToastMessage;
