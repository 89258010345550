import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import RouteComponent from "@routes/RouteComponent";
import ToastMessage from "@common/toast-message/ToastMessage";
import Footer from "@common/footer/Footer";
import Navbar from "@common/Navbar";
import { getPageTitleByLocation } from "@utilities/page-titles";

function App() {
  const location = useLocation();

  function renderPageTitle() {
    const pageTitle = getPageTitleByLocation(location);

    return (
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    );
  }

  // show navbar, sidebar and footer only if current path is employee-credential-profile path
  function renderNavbar() {
    return <Navbar />;
  }

  function renderFooter() {
    return <Footer />;
  }

  return (
    <ToastMessage>
      {renderPageTitle()}
      <div className="wrapper">
        {renderNavbar()}
        <div className={"content-wrapper"}>
          <RouteComponent />
        </div>
        {renderFooter()}
      </div>
    </ToastMessage>
  );
}

export default App;
