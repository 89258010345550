import { DEFAULT_PAGE_LIMIT } from "@constants/default-page-size";
import { IPrivilegeFormDataModel } from "@helpers/interfaces/privilege-viewer";

export function setDefaultPrivilegeFormData(): IPrivilegeFormDataModel {
  const data = {
    organizationName: "",
    providerLastName: "",
    providerDob: "",
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_LIMIT,
  };

  return data;
}
