import toastTypes from "@constants/toast-types";
import axiosClient from "@features/axiosClient";
import { credTrackingActions } from "@features/cred-tracking";
import { toastMessageActions } from "@features/ui/ToastMessage";
import { handleServerInteractionError } from "@utilities/services";

function showLoading(dispatch: any) {
  dispatch(credTrackingActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(credTrackingActions.updateLoading(false));
}

export const getCredTrackingList = (payload: any) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    const { providerNpi, providerLastName, providerDob, pageIndex, pageSize } =
      payload;
    showLoading(dispatch);
    try {
      const response = await axiosClient.get(
        `api/AveraTrackers/GetCredentialTrackerData?ProviderNpi=${providerNpi}&ProviderLastName=${providerLastName}&ProviderDob=${providerDob}&PageIndex=${pageIndex}&PageSize=${pageSize}`
      );
      const data = response.data;

      if (response.status === 200) {
        dispatch(credTrackingActions.updateCredTrackingList(data));
      } else {
        dispatch(
          toastMessageActions.showToast({
            message: "",
            type: toastTypes.DANGER,
          })
        );
      }
      hideLoading(dispatch);
      resolve(response);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

export const getCredTrackingJobWorkflow =
  (payload: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      const { credentialingTrackingId, pageIndex, pageSize } = payload;
      showLoading(dispatch);
      try {
        const response = await axiosClient.get(
          `api/AveraTrackers/GetJobAppWorkflow?CredentialingTrackingId=${credentialingTrackingId}&PageIndex=${pageIndex}&PageSize=${pageSize}`
        );
        const data = response.data;

        if (response.status === 200) {
          dispatch(credTrackingActions.updateCredTrackingJobWorkflow(data));
        } else {
          dispatch(
            toastMessageActions.showToast({
              message: "",
              type: toastTypes.DANGER,
            })
          );
        }
        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };
