import stepperSteps from "@constants/json-data/master-data/stepperSteps";
import styles from "./StepperComponent.module.scss";
import { useAppSelector } from "@hooks/index";
import moment from "moment";

const StepperComponent = () => {
  const steps = useAppSelector(
    (state) => state.credTracking.credTrackingJobWorkflow
  );

  const filteredSteps = steps?.page?.reduce((acc: any, job: any) => {
    const existingJob = acc.find((j: any) => j.jobStatus === job.jobStatus);
    if (existingJob) {
      const existingDate = moment(existingJob.createdDate);
      const currentDate = moment(job.createdDate);
      // Keep the job with the latest createdDate
      if (currentDate.isAfter(existingDate)) {
        // Replace the existing job with the new one if it's more recent
        acc = acc.map((j: any) => (j.jobStatus === job.jobStatus ? job : j));
      }
    } else {
      // If the jobStatus is not found, add it
      acc.push(job);
    }
    return acc;
  }, []);

  const mergedStepperData: any = stepperSteps.map((step) => {
    // Find matching step in API response
    const matchedStatus =
      filteredSteps &&
      filteredSteps.length > 0 &&
      filteredSteps.find((job: any) => job.jobStatus === step.value);

    if (matchedStatus) {
      return {
        ...step,
        totalCount: matchedStatus.totalCount,
        isCompleted: matchedStatus.isCompleted,
        createdDate: matchedStatus.createdDate,
        createdBy: matchedStatus.createdBy,
        updatedBy: matchedStatus.updatedBy,
        updatedDate: matchedStatus.updatedDate,
        userId: matchedStatus.userId,
        organizationId: matchedStatus.organizationId,
      };
    }
    return step;
  });
  const activeStepIndex = mergedStepperData?.findLastIndex(
    (step: any) =>
      filteredSteps?.some((job: any) => job.jobStatus === step.value) // Match based on the jobStatus from API
  );

  return (
    <div id={styles.stepperComponentMain}>
      <div className="row">
        <div className="col-md-12">
          <ul className={styles.stepperWrapper}>
            {mergedStepperData.map((step: any, index: number) => {
              const created_date = step.createdDate
                ? moment(step.createdDate).format("MM/DD/YYYY")
                : "";
              let stepStatus = ""; // Default state: inactive
              if (index < activeStepIndex) {
                // All steps before the active step are completed
                stepStatus = styles.completed;
              } else if (index === activeStepIndex) {
                // The active step
                stepStatus = styles.completed;
              } else if (index === activeStepIndex + 1) {
                // The step after the active step should be marked as active
                stepStatus = styles.active;
              }

              return (
                <li
                  key={index}
                  className={`${styles.stepperItem} ${stepStatus}`}
                >
                  <div className={styles.stepName}>{step.label}</div>
                  <div className={styles.stepCounter}></div>
                  {created_date && (
                    <div className={styles.stepBottomTxt}>{created_date}</div>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default StepperComponent;
