import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IMastersDataModel } from "@helpers/interfaces/master/index";

const initialState = {
  propertyListByPropertyType: [] as Array<IMastersDataModel>,
};

const mastersSlice = createSlice({
  name: "masters",
  initialState,
  reducers: {
    updateLevelOfExperience: (
      state,
      action: PayloadAction<Array<IMastersDataModel>>
    ) => {
      state.propertyListByPropertyType = action.payload;
    },
  },
});

export const mastersActions = mastersSlice.actions;
export default mastersSlice.reducer;
