import { ILocationControlState } from "@helpers/uiInterfaces/common";

export function parseSelectControlsData(data: Array<ILocationControlState>) {
  const _data = [];

  for (let item of data) {
    const object = {
      label: item.name,
      value: item.id,
    };

    _data.push(object);
  }

  return _data;
}

export function checkObjectIsEmpty(object: Object) {
  if (Object.keys(object).length === 0 && object.constructor === Object) {
    return true;
  }

  return false;
}

export const encryptValueIntoCipherValue = (value: any) => {
  const encryptionKey: string = process.env.REACT_APP_AES_ENCRYPTION_KEY!;
  let cipherText = CryptoJS.AES.encrypt(value, encryptionKey).toString();

  return cipherText;
};

export const decryptCipherValueIntoPlainValue = (data: any) => {
  const decryptionKey: string = process.env.REACT_APP_AES_ENCRYPTION_KEY!;
  let bytes = CryptoJS.AES.decrypt(data, decryptionKey);
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const isValidDate = (dateStr: string): boolean => {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

  if (!dateRegex.test(dateStr)) {
    return false;
  }

  const [year, month, day] = dateStr.split("-").map(Number);

  if (year <= 1900) {
    return false;
  }

  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};
