import { DEFAULT_PAGE_LIMIT } from "@constants/default-page-size";
import { ICredFormDataModel } from "@helpers/interfaces/cred-tracking";

export function setDefaultCredTrackingFormData(): ICredFormDataModel {
  const data = {
    providerNpi: "",
    providerLastName: "",
    providerDob: "",
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_LIMIT,
  };

  return data;
}
