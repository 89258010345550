import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const affiliationTrackingListColumns: Array<IApplicationTableColumn> = [
  {
    id: "hospitalName",
    label: "Hospital Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "isPrimaryLabel",
    label: "Is Primary",
    type: applicationTableColumnsTypes.STRING,
  },
  // {
  //   id: "departmentName",
  //   label: "Department Name",
  //   type: applicationTableColumnsTypes.STRING,
  // },
  {
    id: "afflicationStartDate",
    label: "Affiliation Start Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "afflicationEndDate",
    label: "Affiliation End Date",
    type: applicationTableColumnsTypes.DATE,
  },
  {
    id: "isActiveLabel",
    label: "Status",
    type: applicationTableColumnsTypes.STRING,
  },
];

export default affiliationTrackingListColumns;
