import { IPrivilegeFormDataModel } from "@helpers/interfaces/privilege-viewer";

export interface IPrivilegingFormErrors {
  organizationName: string;
  providerDob: string;
  providerLastName: string;
}

export const validatePrvilegeViewerForm = (values: IPrivilegeFormDataModel) => {
  const errors = {} as IPrivilegingFormErrors;

  if (!values.organizationName) {
    errors.organizationName = "Required";
  }

  if (!values.providerDob) {
    errors.providerDob = "Required";
  }

  if (!values.providerLastName) {
    errors.providerLastName = "Required";
  }

  return errors;
};
