import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import routesNames from "@constants/route-names";
import companyLogo from "@images/avera-logo.jpg";

const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<any>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // Attach listener only if the dropdown is open
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    // Cleanup event listener on component unmount or when the dropdown closes
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const companyLogoAttributes = {
    src: companyLogo,
    className: "w-100 cursor-pointer",
    onClick() {
      navigate(routesNames.dashboardRoute);
    },
  };

  return (
    <nav className="main-header navbar navbar-expand">
      <div className="container-fluid m-container">
        <div className="menuBar d-flex align-items-center gap-4 p-2">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <img {...companyLogoAttributes} alt="Company Logo" />
            </li>
          </ul>
        </div>
        <div className="top_header">
          <h1>Credentialing . Affiliation . Privilege . Tracker</h1>
        </div>
        {/* Right navbar links */}
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li>
              <div
                className="float-start cursor-pointer"
                onClick={() => navigate(routesNames.dashboardRoute)}
              >
                Home
              </div>
            </li>
            <li>
              <div className="float-start cursor-pointer">
                <a
                  href={`${process.env.REACT_APP_WATCHDOG_APP_URL}/IncidentTracking/CreateTicket/ZWIwNzJmYTgtMzVlNi00MzFlLWI0NmItMjAxM2JlNGJmNGEx`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </li>
            <li className="dropdown user user-menu" ref={dropdownRef}>
              <div className="float-start">
                <button
                  className="dropdown-toggle d-flex align-items-center table-link-button fw-normal text-black text-decoration-none"
                  data-toggle="dropdown"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleDropdown();
                  }}
                >
                  Sign In
                </button>
                {isOpen && (
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        href={`${process.env.REACT_APP_WATCHDOG_APP_URL}/Account/LogOn`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        Admin Portal
                      </a>
                    </li>
                    <li>
                      <a
                        href={`${process.env.REACT_APP_PROVIDER_PORTAL_APP_URL}/login`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => setIsOpen(!isOpen)}
                      >
                        Provider/Staff Portal
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
