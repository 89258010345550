import { IAffiliationTrackingPaginationModel } from "@helpers/interfaces/affiliation-tracking";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  affiliationTrackingList: {} as IAffiliationTrackingPaginationModel,
  error: null as any,
  loading: false,
};

const affiliationTrackingSlice = createSlice({
  name: "affiliationTracking",
  initialState,
  reducers: {
    updateAffiliationTrackingList: (
      state,
      action: PayloadAction<IAffiliationTrackingPaginationModel>
    ) => {
      state.affiliationTrackingList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.affiliationTrackingList = {} as IAffiliationTrackingPaginationModel;
      state.loading = false;
      state.error = null;
    },
  },
});

export const affiliationtrackingActions = affiliationTrackingSlice.actions;
export default affiliationTrackingSlice.reducer;
