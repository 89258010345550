import { combineReducers } from "@reduxjs/toolkit";
import uiReducers from "@features/ui/UIReducers";
import CredTracking from "@features/cred-tracking/index";
import AffiliationTracking from "@features/affiliation-tracking/index";
import PrivilegeViewer from "@features/privilege-viewer/index";
import MasterReducer from "@features/master/index";

const rootReducer = combineReducers({
  ui: uiReducers,
  master: MasterReducer,
  credTracking: CredTracking,
  affiliationTracking: AffiliationTracking,
  privilegeViewer: PrivilegeViewer,
});

export default rootReducer;
