import { DEFAULT_PAGE_LIMIT } from "@constants/default-page-size";
import { IAffiliationFormDataModel } from "@helpers/interfaces/affiliation-tracking";

export function setDefaultAffiliationTrackingFormData(): IAffiliationFormDataModel {
  const data = {
    providerNpi: "",
    providerLastName: "",
    providerDob: "",
    requestorName: "",
    requestorOrganizationName: "",
    requestorEmailAddress: "",
    requestorAddress: null,
    requestorCity: 0,
    requestorState: 0,
    requestorZip: null,
    isSubmit: false,
    pageIndex: 1,
    pageSize: DEFAULT_PAGE_LIMIT,
  };

  return data;
}
