import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

const requestHandler = (request: AxiosRequestConfig) => {
  return request;
};

const responseHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: AxiosError) => {
  const _error = error.response;

  if (typeof _error === "undefined") {
    return error;
  }

  if (_error.status !== 500) {
    return _error;
  }

  throw new Error("Something went wrong. Internal server error.");
};

axiosClient.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

axiosClient.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default axiosClient;
