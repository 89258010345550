const stepperSteps = [
  { label: "Job Created", value: "Job Created" },
  { label: "App Sent", value: "App Sent" },
  { label: "App Received", value: "App Received" },
  { label: "App In Review", value: "App In Review" },
  { label: "App Resent", value: "App Resent" },
  { label: "App Complete", value: "App Complete (Clean App)" },
  { label: "PSV In Process", value: "PSV In Process" },
  { label: "PSV In Review", value: "PSV In Review" },
  { label: "Credentialing Completed", value: "Credentialing Completed" },
  { label: "Credentialing Approved", value: "Credentialing Approved" },
];

export default stepperSteps;
