import React from "react";

import { NavLink } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import routesNames from "@constants/route-names";

import { IBreadcrumbProps } from "helpers/uiInterfaces/common";

import initialRoutes from "@routes/routes-data/initial-routes";

import { ReactComponent as ChevronRightIcon } from "@images/chevron-right-icon.svg";

import { isLastBreadcrumb } from "./utilities";

import styles from "./Breadcrumbs.module.scss";

const Breadcrumbs: React.FC<IBreadcrumbProps> = (props) => {
  const { data } = props;

  const breadcrumbs = useBreadcrumbs(initialRoutes);

  function renderBreadcrumbLink(
    path: string,
    breadcrumb: any,
    index: number,
    _breadcrumbs: Array<any>
  ) {
    if (path === routesNames.dashboardRoute) {
      const breadcrumbControlAttributes = {
        href: routesNames.dashboardRoute,
        className: styles.breadcrumbLink,
      };

      return (
        <a key={path} {...breadcrumbControlAttributes}>
          {breadcrumb}
        </a>
      );
    }

    if (isLastBreadcrumb(index, _breadcrumbs) === true) {
      return (
        <label className={`${styles.breadcrumbLink} ${styles.inactive}`}>
          {breadcrumb}
        </label>
      );
    }

    const breadcrumbControlAttributes = {
      to: path,
      className: styles.breadcrumbLink,
    };

    return (
      <NavLink key={path} {...breadcrumbControlAttributes}>
        {breadcrumb}
      </NavLink>
    );
  }

  function renderBreadcrumbSeparator(index: number, _breadcrumbs: Array<any>) {
    if (isLastBreadcrumb(index, _breadcrumbs) === true) {
      return;
    }

    return <ChevronRightIcon className={styles.chevronIcon} />;
  }

  function renderBreadcrumbs() {
    if (typeof data !== "undefined") {
      return data.map((item, index) => (
        <div key={index} className={styles.breadCrumbItem}>
          {renderBreadcrumbLink(item.path, item.breadcrumb, index, data)}
          {renderBreadcrumbSeparator(index, data)}
        </div>
      ));
    }

    return breadcrumbs.map(({ match, breadcrumb }, index) => (
      <div key={index} className={styles.breadCrumbItem}>
        {renderBreadcrumbLink(match.pathname, breadcrumb, index, breadcrumbs)}
        {renderBreadcrumbSeparator(index, breadcrumbs)}
      </div>
    ));
  }

  return <div className={styles.breadcrumbsMain}>{renderBreadcrumbs()}</div>;
};

export default Breadcrumbs;
