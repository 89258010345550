import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAngleLeft,
  faAnglesRight,
  faAngleRight,
  faEllipsis,
} from "@fortawesome/free-solid-svg-icons";

import { usePagination, DOTS } from "@hooks/usePagination";

import pageLimit from "@constants/json-data/page-limit";
import { IPaginatedTableFooterProps } from "@helpers/uiInterfaces/common";

import styles from "./PaginatedTable.module.scss";

const PaginatedTableFooter: React.FC<IPaginatedTableFooterProps> = (props) => {
  const {
    rowsPerPage,
    currentPage,
    totalPage,
    onPageChange,
    onPageLimitChange,
  } = props;

  const paginationRange = usePagination(totalPage, rowsPerPage, 1, currentPage);

  function renderPageLimitControl() {
    const pageLimitControlAttributes = {
      className: styles.pageLimitControl,
      value: rowsPerPage,
      onChange(event: React.ChangeEvent<HTMLSelectElement>) {
        onPageLimitChange(+event.target.value);
      },
    };

    return (
      <div className={styles.pageLimitControl}>
        <label>Rows per page:</label>
        <select {...pageLimitControlAttributes}>
          {pageLimit.map((limit, index) => (
            <option key={index} value={limit.value}>
              {limit.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  function renderPageNumberControl(pageNumber: any, index: number) {
    if (pageNumber === DOTS) {
      return (
        <FontAwesomeIcon
          icon={faEllipsis}
          className={styles.ellipsis}
          key={index}
        />
      );
    }

    let pageNumberControlClassName = styles.pageNavigationControl;

    if (currentPage === pageNumber) {
      pageNumberControlClassName += ` ${styles.active}`;
    }

    const pageNumberControlAttributes = {
      className: pageNumberControlClassName,
      onClick() {
        onPageChange(pageNumber);
      },
    };

    return (
      <button key={index} {...pageNumberControlAttributes}>
        {pageNumber}
      </button>
    );
  }

  function renderPaginationControls() {
    const tooltipAttributes = {
      className: styles.pageNavigationControl,
    };

    const firstPageControlAttributes = {
      ...tooltipAttributes,
      title: "First page",
      disabled: currentPage === 1 ? true : false,
      onClick() {
        onPageChange(1);
      },
    };

    const previousPageControlAttributes = {
      ...tooltipAttributes,
      title: "Previous page",
      disabled: currentPage === 1 ? true : false,
      onClick() {
        onPageChange(currentPage - 1);
      },
    };

    const nextPageControlAttributes = {
      ...tooltipAttributes,
      title: "Next page",
      disabled: currentPage === totalPage ? true : false,
      onClick() {
        onPageChange(currentPage + 1);
      },
    };

    const lastPageControlAttributes = {
      ...tooltipAttributes,
      title: "Last page",
      disabled: currentPage === totalPage ? true : false,
      onClick() {
        onPageChange(totalPage);
      },
    };

    return (
      <div className={styles.paginationControls}>
        <button {...firstPageControlAttributes}>
          <FontAwesomeIcon icon={faAnglesLeft} />
        </button>
        <button {...previousPageControlAttributes}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>

        {paginationRange?.map((pageNumber, index) =>
          renderPageNumberControl(pageNumber, index)
        )}

        <button {...nextPageControlAttributes}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
        <button {...lastPageControlAttributes}>
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>
    );
  }

  return (
    <div className={styles.paginatedTableFooter}>
      {renderPageLimitControl()}
      {renderPaginationControls()}
    </div>
  );
};

export default PaginatedTableFooter;
