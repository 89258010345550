import { Routes, Route } from "react-router-dom";

import Spinner from "@common/spinner/Spinner";

import { IRoute } from "helpers/uiInterfaces/common";

import initialRoutes from "./routes-data/initial-routes";
import { Suspense } from "react";

const RouteComponent = () => {
  function renderRoute(route: IRoute, index: number) {
    const routeAttributes = {
      path: route.path,
      element: route.element,
    };

    return <Route key={index} {...routeAttributes} />;
  }

  return (
    <Suspense fallback={<Spinner fullscreen={true} />}>
      <Routes>
        {initialRoutes.map((route, index) => renderRoute(route, index))}
      </Routes>
    </Suspense>
  );
};

export default RouteComponent;
