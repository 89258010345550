import styles from "./Dashboard.module.scss";
import routesNames from "@constants/route-names";
import { useNavigate } from "react-router-dom";
import CredImg1 from "@images/credentialing-img1.png";
import CredImg2 from "@images/credentialing-img2.png";
import OrangeShape1 from "@images/orange-shape1.svg";
import OrangeShape2 from "@images/orange-shape2.svg";
import OrangeShape3 from "@images/shape3.svg";
import OrangeShape4 from "@images/shape4.svg";
import AffiliationImg1 from "@images/affiliation-img1.png";
import AffiliationImg2 from "@images/affiliation-img2.png";
import PrivilegeImg1 from "@images/Privilege-img1.png";
import PrivilegeImg2 from "@images/Privilege-img2.png";

const Dashboard = () => {
  const navigate = useNavigate();

  const renderSupportLink = () => {
    return (
      <a
        href={`${process.env.REACT_APP_WATCHDOG_APP_URL}/IncidentTracking/CreateTicket/ZWIwNzJmYTgtMzVlNi00MzFlLWI0NmItMjAxM2JlNGJmNGEx`}
        target="_blank"
        rel="noreferrer"
      >
        Clicking Here
      </a>
    );
  };

  const renderCredTrackingSection = () => {
    const credTrackingControlAttributes = {
      className: "btn btnorg mt-3",
      onClick() {
        navigate(routesNames.credentialingTrackingRoute);
      },
    };

    return (
      <section className={`${styles.sectionSpace} ${styles.innerSec}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.imageUpaind} ${styles.extraSpace}`}>
                <img src={CredImg1} alt="CredImg1" className="img-fluid" />
                <div className={styles.imagePosition}>
                  <img src={CredImg2} alt="CredImg2" className="img-fluid" />
                </div>
                <div className={styles.orangeShape1}>
                  <img src={OrangeShape1} alt="shape" />
                </div>
                <div className={styles.orangeShape2}>
                  <img src={OrangeShape2} alt="shape" />
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.bannerText}`}>
              <article>
                <h2 className={styles.heading}>
                  <span>Credentialing</span> Tracking
                </h2>
                <p className={styles.paragraph}>
                  Avoid multiple emails and phone calls and to track your
                  providers credentialing application real time. This module
                  assists in tracking your providers credentialing status with
                  Avera Credentialing Verification Services. Please have the
                  NPI, Data of Birth and the last name of your provider to
                  verify the status. If you notice any discrepancies with the
                  request, please open a SUPPORT TICKET by {renderSupportLink()}
                </p>
                <button {...credTrackingControlAttributes}>
                  Credentialing Tracking
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderAffiliationTrackingSection = () => {
    const affiliationTrackingControlAttributes = {
      className: "btn btnorg mt-3",
      onClick() {
        navigate(routesNames.affiliationTrackingRoute);
      },
    };

    return (
      <section
        className={`${styles.sectionSpace} ${styles.innerSec} ${styles.bgLightBlue}`}
      >
        <div className="container">
          <div className={`row ${styles.mobileView}`}>
            <div className={`col-lg-6 ${styles.bannerText}`}>
              <article>
                <h2 className={styles.heading}>
                  <span>Affiliation</span> Tracking
                </h2>
                <p className={styles.paragraph}>
                  Verify your provider’s affiliation and download an affiliation
                  letter by clicking on the link below. If you notice any
                  discrepancies with the request, please open a SUPPORT TICKET
                  by {renderSupportLink()}
                </p>
                <button {...affiliationTrackingControlAttributes}>
                  Affiliation Tracking
                </button>
              </article>
            </div>
            <div className="col-lg-6 text-lg-end">
              <div className={`${styles.imageUpaind} ${styles.extraSpaceLeft}`}>
                <img
                  src={AffiliationImg1}
                  alt="AffiliationImg1"
                  className="img-fluid"
                />
                <div className={styles.imagePositionLeft}>
                  <img
                    src={AffiliationImg2}
                    alt="AffiliationImg2"
                    className="img-fluid"
                  />
                </div>
                <div className={styles.orangeShape3}>
                  <img src={OrangeShape3} alt="shape" />
                </div>
                <div className={styles.orangeShape4}>
                  <img src={OrangeShape4} alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderPrivilegeViewerSection = () => {
    const privilegeViewerControlAttributes = {
      className: "btn btnorg mt-3",
      onClick() {
        navigate(routesNames.privilegeViewerRoute);
      },
    };

    return (
      <section className={`${styles.sectionSpace} ${styles.innerSec}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className={`${styles.imageUpaind} ${styles.extraSpace}`}>
                <img
                  src={PrivilegeImg1}
                  alt="PrivilegeImg1"
                  className="img-fluid"
                />
                <div className={styles.imagePosition}>
                  <img
                    src={PrivilegeImg2}
                    alt="PrivilegeImg2"
                    className="img-fluid"
                  />
                </div>
                <div className={styles.orangeShape1}>
                  <img src={OrangeShape1} alt="shape" />
                </div>
                <div className={styles.orangeShape2}>
                  <img src={OrangeShape2} alt="shape" />
                </div>
              </div>
            </div>
            <div className={`col-lg-6 ${styles.bannerText}`}>
              <article>
                <h2 className={styles.heading}>
                  <span>Privilege</span> Viewer
                </h2>
                <p className={styles.paragraph}>
                  View your providers privileges by clicking on the link below.
                  If you notice any discrepancies with the request, please open
                  a SUPPORT TICKET by {renderSupportLink()}
                </p>
                <button {...privilegeViewerControlAttributes}>
                  Privilege Viewer
                </button>
              </article>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <div id={styles.dashboardWrapper}>
      {renderCredTrackingSection()}
      {renderAffiliationTrackingSection()}
      {renderPrivilegeViewerSection()}
    </div>
  );
};

export default Dashboard;
